.about{
    background-color: #ABDEF1;
    padding-bottom: 16px;
}
.head{
    text-align: center;
    font-size: 32px;
    color: #463A8C;
}
.flex{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
}
.img{
    width: 50%;
    text-align: center;
}
.img img{
    width: 80%;
}
.text{
    padding-left: 120px;
    width: 50%;
}
.text h4{
    font-size: 24px;
    font-weight: 500;
}
.cta{
    text-align: center;
}
.cta h3{
    font-size: 32px;
    color: #463A8C;
    margin: 0;
}
.cta h4{
    font-size: 24px;
    font-weight: 500;
    margin: 16px 0;
}

.copyright{
    border-top: 2px white solid;
}
.copyright h6{
    font-size: 8px;
    font-weight: 400;
}

@media screen and (max-width:640px) {
    .flex{
        flex-wrap: wrap;
    }
    .img{
        width: 80vw;
    }
    .text{
        padding: 0;
        margin: auto;
        width: unset;
        margin: 2% 5%;
    }
    .text h4{
        font-size: 4vw; 
    }
    .cta h4{
        font-size: 4vw;
    }
    .cta h3{
        font-size: 7vw;
    }
    .about{
        padding:2vh;
    }
    .head h2{
        margin-bottom: 15%;
    }
}