.landing{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 80px;
    margin-bottom: 80px;
}
.text h2{
    font-size: 48px;
    color: #728CBF;
    margin: auto 120px 16px auto;
}
.text .mobileText{
    display: none;
}
.text h4{
    font-size: 24px;
    font-weight: 500;
}

@media screen and (max-width:640px)
{
  .landing{
      padding :2px;
      flex-wrap: wrap;
      flex-direction: column-reverse;
  }
  .text{
      text-align: center;
  }
  .text h2{
      display: none;
  }
  .text .mobileText{
      display: block;
      text-align: center;
      margin: auto; 
      font-size: 8vw;
      margin-top: 15%;
  }
  .text h4{
      text-align: center;
      font-size: 4vw;
  } 
  .land{
      width: 80vw;
      display: block;
      margin: auto;
  }
}