.privacy h2{
    font-size: 48px;
    color: #463A8C;
    text-align: center;
}
.body h3{
    font-size: 28px;
}
.body h5{
    font-size: 16px;
    font-weight: 500;
}