.cardContainer
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 30px;
    background-color: var(--cardBlue_t);
    border-radius: 16px;
    text-align: center;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.116);
    transition: 0.3s;
}

.cardContainer:hover
{
    transform: scale(1.02);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.233);
    transition: 0.3s;
}

.cardContainer:hover > .img
{
    height: 40%;
}

.img
{
    height: 50%;
    margin: 20px;
    transition: 0.3s;
}

.title
{
    color: var(--purple_t);
    font-weight: 600;
}

@media screen and (max-width:640px) {
    img{
        width: 70vw;
    }
    
}