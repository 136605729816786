.footer{
    background-color: #45405C;
    color: white;
    padding-top: 32px;
}
.flex{
    border-bottom: 1px white solid;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0%;
    padding-bottom: 16px;
}

.about{
    width: 480px;
}
.about h3{
    font-size: 32px;
    margin: 0;
}
.about h4{
    font-size: 16px;
    font-weight: 500;
    margin: 16px auto;
}


.links{
    display: flex;
    flex-direction: column;
}
.links h3{
    font-size: 32px;
    margin-bottom: 8px;
    margin-top: 0%;
}
.links a{
    text-decoration: none;
    color: white;
    margin-bottom: 16px;
}

.legal{
    display: flex;
    flex-direction: column;
}
.legal h3{
    font-size: 32px;
    margin-bottom: 8px;
    margin-top: 0%;
}
.legal a{
    margin-bottom: 16px;
}

.address h3{
    font-size: 32px;
    margin: 0;
}
.address h4{
    font-size: 16px;
    font-weight: 500;
    margin: 8px auto;
}
.contactus{
    margin-top: 32px;
}
.contactus div
{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.contactus i
{
    margin: 0;
    margin-right: 8px;
}
.contactus h3{
    font-size: 32px;
    margin-bottom: 16px;
    margin-top: 0%;
}
.contactus a{
    text-decoration:none;
    color: white;
    font-size: 24px;
    margin-right: 16px;
}
.contactus h4
{
    margin: 12px 0;
    font-weight: 500;
}
.copyright h6{
    margin: 0%;
    font-size: 12px;
    font-weight: 400;
    margin-top: 24px;
}

@media screen and (max-width:640px) {
    .flex{
        flex-direction: column;
        padding-right: 4px;
    }
    .about{
        width: unset;
    }
    .links{
        display: none;
    }
    .copyright{
        text-align: center;
        margin-bottom: 5%;
    }
}