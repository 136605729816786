.head{
    text-align: center;
}
.head h3{
    font-size: 40px;
    color: #463A8C;
}
.head h4{
    font-size: 24px;
    font-weight: 500;
}
.services{
    padding: 50px;
    display: grid;  
    grid-gap: 1.8rem;
    grid-template-columns: repeat(3, minmax(300px, 1fr));
}

@media screen and (max-width:640px) {
    .head h4{
        display: none;
    }
    .services{
        display: flex;
        flex-direction: column;
        padding: unset;
    }
    #services{
        margin-top: 5%;
    }
}