.faqConatiner
{
    padding: 30px 120px 80px;
}

.head{
    text-align: center;
}
.head h3{
    font-size: 40px;
    color: #463A8C;
}
.head h4{
    font-size: 24px;
    font-weight: 500;
}

.list
{
    display: flex;
    flex-direction: column;
    padding: 30px 50px 50px;
    background-color: var(--cardBlue_t);
    border-radius: 16px;

}

.faq
{
    padding: 0 20px;
    margin: 0px 20px;
}

.ques
{
    border-bottom: 1px solid var(--purple_t);
    margin: 5px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.ques h3 
{
    font-weight: 500;
}

.ques i
{
    color: var(--purple_t);
    transform: scale(1.1);
    margin-right: 2px;
}

@media screen and (max-width:640px) {
    .faqConatiner{
        padding: 0 8vw;
        margin-bottom: 10vh ;
    }
    .faq{
        padding: 0 .5vw;
    }
    .ques{
        margin-right: 1vw;
    }
}