.overlay
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.301);
}

.modalContainer
{
    background-color: white;
    border-radius: 12px;
    width: 70%;
    height: 80vh;
    overflow-y: scroll;
    position: fixed;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px 20px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.246);
    overflow-y: scroll;
}
.form
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 50px;
}

.inputGroup
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: 15px 0;
}
.inputGroup textarea 
{
    font-family: 'Open Sans', sans-serif;
}
.inputGroupHorizontal
{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.label
{
    color: var(--purple_t);
    width: 100%;
    text-align: left;
    margin: 10px 0;
    font-size: 1.2rem;
    font-weight: 500;
}

.input
{
    border: 1px solid var(--darkBlue_t);
    border-radius: 5px;
    font-size: 1.2rem;
    padding: 10px;
    color: var(--purple_t);
    outline: none;
    resize: none;
}

.buttonsGroup
{
    display: flex;
    flex-direction: row;
    justify-content: right;
    margin: 20px 0px 0px;
}

.modalContainer::-webkit-scrollbar-thumb
{
    background-color: var(--purpleTransparent_t);
    border: 4px solid transparent;
    border-radius: 8px;
    background-clip: padding-box;  
    margin: 10px;
}

.modalContainer::-webkit-scrollbar
{
    width: 16px;
}

@media screen and (max-width:640px) {
    .inputGroupHorizontal{
        flex-direction:column ;
        justify-content: unset;
        align-items: unset;
    }
    
}