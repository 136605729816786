:root
{
  --pink_t: #D967A5;
  --purple_t: #463A8C;
  --darkBlue_t: #728CBF;
  --lightBlue_t: #ABDEF1;
  --cardBlue_t: #D3EEF8;
  --footer_t: #45405C;
  --purpleTransparent_t: #463a8c86;
}
html
{
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a
{
  cursor: pointer;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
