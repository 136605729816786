.nav
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 80px;
}
.logo
{
    height: 100%;
    cursor: pointer;
}
.toggler{
    display: none;
}

@media screen and (max-width:640px) {
    .nav{
        margin-bottom: 10%;
    }
    .navButtonsOpen{
        align-items: center;
        background-color:#728CBF;
        text-align: center;
        position: relative;
    }
    .tabs{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: left;
    }
    .navButtonsClose{
        display: none;
    }
    .logo{
        width: 12vw;
        height: auto;
    }
    .toggler{
        display: block;
        cursor: pointer;
        color: #728CBF;
    }
    .toggler i{
        width: 40px;
    }
}

@media screen and (min-width:640px) {
    .navButtonsOpen{
        display: none;
    }
}
